import React, { useState, useEffect } from 'react'
import Ticker from 'react-ticker'
import PageVisibility from 'react-page-visibility'
import styled from 'styled-components'

const PhotoStrip = ({
  height: pHeight,
  images
}) => {

  const [pageIsVisible, setPageIsVisible] = useState(true)
 
  const handleVisibilityChange = (isVisible) => {
    setPageIsVisible(isVisible)
  }

  let height
  if(typeof window !== 'undefined' && window.innerWidth < 1024) {
    height = 215

  } else {
    height = pHeight || 430
  }

  return (
    <PhotoStrip_wrapper staHeight={height}>
      <PageVisibility
        className="photoStrip"
        onChange={handleVisibilityChange} >
        <div className="photoStrip-inner">
          {pageIsVisible && (
            <Ticker>
              {({ index }) => (
                <>
                  <img style={{
                    height,
                    width: `${(images[index % images.length].width / images[index % images.length].height) * height}px`,
                    margin: '0 1px'
                  }} src={images[index % images.length].src} alt={images[index % images.length].alt} />
                </>
              )}
            </Ticker>
          )}
        </div>
      </PageVisibility>
    </PhotoStrip_wrapper>
  )
}

const PhotoStrip_wrapper = styled.div`
  height: ${props => props.staHeight}px;
`

export default PhotoStrip
